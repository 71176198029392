<template>
  <form-wrapper :title="config.title" :rows="rows[config.type]">
    <i-autocomplete
      slot="slot11"
      placeholder="Регион"
      :lib="config.region"
      :name="config.type"
      :edit="formData[`${config.type}_region`]"
      @selected="(item, name) => $emit('setRegion', item, name)"
    />
    <i-autocomplete
      slot="slot12"
      placeholder="Населенный пункт"
      :lib="config.kladr"
      :name="config.type"
      :disabled="!formData[`${config.type}_region`]"
      :relation="config.kladr.params.regionId"
      :edit="formData[config.type + '_city']"
      @selected="(item, name) => $emit('setKladr', item, name)"
    />
    <el-input
      slot="slot21"
      v-model="formData[`${config.type}_address`]"
      placeholder="Адрес"
      autocomplete="nope"
      @input="val => $emit('setAddress', val)"
    />
  </form-wrapper>
</template>

<script>
import IAutocomplete from '@/components/Ui/Autocomplete'
import { addTenderConfig } from '@/components/Tender/constants'
import FormWrapper from '@/components/Wrappers/FormWrapper'

export default {
  name: 'LoadUnloadFields',
  components: { FormWrapper, IAutocomplete },
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    form: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      rows: addTenderConfig
    }
  },
  computed: {
    formData: {
      get() {
        return this.form
      }
    }
  }
}
</script>
