<template>
  <i-dialog :name="name">
    <p slot="title">{{ id ? 'РЕДАКТИРОВАНИЕ' : 'СОЗДАНИЕ' }} ГРУЗОПОЛУЧАТЕЛЯ</p>
    <el-form
      ref="editConsigneeForm"
      :model="form"
      :rules="rules"
      label-position="left"
      class="i-components"
      @submit.native.prevent
      @keyup.enter.native="onClick"
    >
      <el-row type="flex" align="middle">
        <el-col :sm="6" :xs="24" class="fs-20 mb-25 pr-15 black mb-m-5">
          Грузополучатель:
        </el-col>
        <el-col :sm="18" :xs="24">
          <el-form-item prop="name">
            <el-input v-model="form.name" autocomplete="nope" />
          </el-form-item>
        </el-col>
      </el-row>
      <load-unload-fields
        :config="config"
        :form="{
          unload_region: form.region,
          unload_city: form.city,
          unload_address: form.address
        }"
        @setAddress="setAddress"
        @setRegion="setRegion"
        @setKladr="setKladr"
      />
      <el-row type="flex" justify="end" class="mt-50 f-wrap">
        <div class="w-m-100 mt-m-20">
          <i-button
            :loading="loading"
            :disabled="loading"
            :text="id ? 'Обновить' : 'Создать'"
            width="250"
            @click="onClick"
          />
        </div>
      </el-row>
    </el-form>
  </i-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import LoadUnloadFields from '@/components/Reusable/LoadUnloadFields'
import IDialog from '@/components/Ui/Dialog'
import IButton from '@/components/Ui/Button'
import { autocompleteConfigUnload } from '@/data/constants'
import { settingsConsigneeFormRules } from './constants'

export default {
  name: 'EditConsigneeModal',
  components: { LoadUnloadFields, IDialog, IButton },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        if (val?.id) {
          this.id = val.id
          this.form = this.updateObject(this.form, val)
        } else {
          this.id = 0
          if (this.$refs.editConsigneeForm)
            this.$refs.editConsigneeForm.resetFields()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getDialog: 'popups/getDialog',
      getLoading: 'consignees/getLoading'
    }),
    loading() {
      return this.getLoading()
    },
    item() {
      return this.getDialog(this.name).data || {}
    }
  },
  data() {
    return {
      name: 'edit-consignee-modal',
      rules: settingsConsigneeFormRules,
      config: autocompleteConfigUnload,
      id: 0,
      form: {
        name: '',
        region: '',
        city: '',
        address: '',
        district: ''
      }
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    ...mapActions('consignees', ['CREATE', 'UPDATE']),
    setAddress(val) {
      this.form.address = val
    },
    setRegion(item) {
      this.config.kladr.params.regionId = item.id
      this.form.region = item.name
      this.form.city = ''
      this.form.district = ''
    },
    setKladr(item) {
      const result = this.loadLocationFilter(item.name)
      this.form.city = result.city
      this.form.district = result.district
    },
    onClick() {
      this.$refs.editConsigneeForm.validate(valid => {
        if (valid) {
          const { id, form: data } = this
          id
            ? this.UPDATE({ id, data }).then(res => {
                if (res !== 'error') this.onClose('обновлен')
              })
            : this.CREATE(data).then(res => {
                if (res !== 'error') {
                  this.onClose('создан')
                  this.$emit('after', res.id)
                }
              })
        }
      })
    },
    onClose(text) {
      this.$message.success(`Грузополучатель успешно ${text}!`)
      this.setDialog({ name: this.name })
    }
  }
}
</script>
