<template>
  <div class="mb-20">
    <el-row class="mb-25">
      <el-button @click="selectBidders" type="text">
        Выбрать из списка перевозчиков
      </el-button>
      <p class="mt-10">Выбрано {{ bidders.length }}</p>
    </el-row>
    <el-row>
      <el-button @click="selectGroups" type="text">
        Выбрать группы перевозчиков
      </el-button>
      <p class="mt-10">Выбрано {{ groups.length }}</p>
    </el-row>

    <tender-form-bidders-modal
      :title="title"
      :bidders="bidders"
      :drop-bidders="dropBidders"
      v-on="$listeners"
    />
    <tender-form-groups-modal
      :title="title"
      :groups="groups"
      :drop-groups="dropGroups"
      v-on="$listeners"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import TenderFormBiddersModal from './TenderFormBiddersModal'
import TenderFormGroupsModal from './TenderFormGroupsModal'

export default {
  name: 'ParticipantBlock',
  components: { TenderFormBiddersModal, TenderFormGroupsModal },
  props: {
    bidders: {
      type: Array,
      default: () => []
    },
    dropBidders: {
      type: Array,
      default: () => []
    },
    groups: {
      type: Array,
      default: () => []
    },
    dropGroups: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    selectBidders() {
      this.setDialog({
        name: 'tender-form-bidders-modal',
        visible: true
      })
    },
    selectGroups() {
      this.setDialog({
        name: 'tender-form-groups-modal',
        visible: true
      })
    }
  }
}
</script>
