<template>
  <div class="mb-30">
    <h4 class="add-tender__section-title custom-title">{{ title }}</h4>
    <div v-if="text" class="add-tender__text">
      Если вы хотите что бы могло быть несколько победителей в тендере,
      минимальный объем должен быть меньше суточного объем кратно
    </div>
    <el-row v-for="(row, i) in rows" :gutter="row.gutter" :key="i">
      <el-col
        v-for="(col, n) in row.cols"
        :span="col.span"
        :xs="col.xs"
        :class="col.className || ''"
        :key="n"
      >
        <el-form-item
          class="add-tender__label"
          :prop="col.prop"
          :label="col.label"
        >
          <slot :name="`slot${i + 1}${n + 1}`" />
          <div v-if="showLinks">
            <el-button type="text" @click="$emit('link')">
              {{ col.link }}
            </el-button>
            <el-button v-if="col.link2" type="text" @click="$emit('link2')">
              {{ col.link2 }}
            </el-button>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'FormWrapper',
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: Boolean,
      default: false
    },
    showLinks: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Array,
      default: () => []
    }
  }
}
</script>
