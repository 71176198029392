<template>
  <i-dialog :name="name" fullscreen class="users-tables-modal">
    <el-row slot="title" class="w-100 f-m-wrap" type="flex">
      <el-col class="title">
        {{ `Выбор перевозчиков для тендера${title ? ':' : ''}` }}
      </el-col>
      <el-col>{{ title }}</el-col>
    </el-row>
    <users-tables-modal-body
      :config="config"
      :chosen="bidders"
      :all="users"
      :pagination="pagination"
      :loading="loading"
      :filters="filters"
      showStatus
      @add="onAdd"
      @remove="onRemove"
      @page="onChangePage"
      @filter="onChangeFilter"
    />
  </i-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IDialog from '@/components/Ui/Dialog'
import UsersTablesModalBody from '@/components/TablesFormsFilters/UsersTablesModalBody'

export default {
  name: 'TenderFormBiddersModal',
  components: { UsersTablesModalBody, IDialog },
  props: {
    bidders: {
      type: Array,
      default: () => []
    },
    dropBidders: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  watch: {
    bidders: {
      immediate: true,
      handler() {
        this.getApprovedUsers()
      }
    }
  },
  data() {
    return {
      name: 'tender-form-bidders-modal',
      config: {
        topTitle: 'Исполнители',
        addButtonTitle: 'Добавить к перевозке',
        bottomTitle: 'Добавлены к перевозке',
        removeButtonTitle: 'Удалить из перевозки'
      },
      filters: {
        is_in_groups: undefined,
        any: ''
      },
      page: 1
    }
  },
  computed: {
    ...mapGetters({
      pagination: 'approvedUsers/getPagination',
      users: 'approvedUsers/getList',
      getLoading: 'approvedUsers/getLoading'
    }),
    loading() {
      return this.getLoading()
    }
  },
  methods: {
    ...mapActions('approvedUsers', ['FETCH']),
    getApprovedUsers() {
      const exclude_id = this.bidders?.flatMap(b => b.id).join(',')
      this.FETCH({ ...this.filters, page: this.page, exclude_id })
    },
    onChangeFilter(key, val) {
      this.filters[key] = val
      this.getApprovedUsers()
    },
    onChangePage(page) {
      this.page = page
      this.getApprovedUsers()
    },
    onAdd(users) {
      const drop = this.dropBidders.filter(b => !users.find(u => u.id === b.id))
      const add = [...this.bidders, ...users]
      this.$emit('update', { drop, add })
    },
    onRemove(users) {
      const drop = [...this.dropBidders, ...users]
      const add = this.bidders.filter(b => !users.find(u => u.id === b.id))
      this.$emit('update', { drop, add })
    }
  }
}
</script>

<style lang="sass">
.tender-form-bidders-modal.is-fullscreen
  max-width: unset
  overflow: hidden
  .title
    max-width: 275px
</style>
