<template>
  <el-autocomplete
    v-model="text"
    :placeholder="placeholder"
    :fetch-suggestions="querySearchAsync"
    size="large"
    value-key="autocompleteKeyName"
    :disabled="disabled"
    :debounce="1000"
    :clearable="true"
    @select="handleSelect"
    @clear="onClear"
    @change="onChange"
  />
</template>

<script>
import ApiFactory from '@/api/ApiFactory'

const RegistryApi = ApiFactory.get('registry')

export default {
  name: 'IAutocomplete',
  props: {
    lib: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    },
    relation: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    edit: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Выберите'
    }
  },
  created() {
    if (this.edit) {
      this.text = this.edit
      this.$emit('selected', { name: this.edit }, this.name)
    }
  },
  watch: {
    edit(val) {
      this.onChange(val)
    }
  },
  data() {
    return {
      text: ''
    }
  },
  methods: {
    onChange(val) {
      if (!val) this.onClear()
    },
    querySearchAsync(queryString, cb) {
      if (queryString.length < 4) {
        cb([])
        return false
      }
      const { params } = this.lib
      params.query = queryString

      RegistryApi.getKladr(params)
        .then(response =>
          cb(this.compositeKeyName(response.data.result, this.lib.keyName))
        )
        .catch(error => console.log(error))
    },
    selectItem(id, name, relation = this.relation) {
      const item = { name, id }
      this.$emit('selected', item, this.name)
      item.relation = relation
    },
    handleSelect(item) {
      this.selectItem(item.id, item.autocompleteKeyName)
    },
    compositeKeyName(data, keys) {
      return data.map(item => {
        item.autocompleteKeyName = ''
        keys.forEach(function (key) {
          if (key !== ' ') item.autocompleteKeyName += item[key]
          else item.autocompleteKeyName += key
        })
        if (item.parents && item.parents.length) {
          item.parents.forEach(function (d) {
            if (d.contentType === 'district') {
              item.autocompleteKeyName += ', '
              keys.forEach(function (key) {
                if (key !== ' ') item.autocompleteKeyName += d[key]
                else item.autocompleteKeyName += key
              })
            }
          })
        }
        return item
      })
    },
    onClear() {
      this.text = ''
      this.$emit('selected', {}, this.name)
    }
  }
}
</script>
