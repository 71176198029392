<template>
  <i-dialog :name="name">
    <p slot="title">{{ id ? 'РЕДАКТИРОВАНИЕ' : 'СОЗДАНИЕ' }} КУЛЬТУРЫ</p>
    <el-form
      ref="editProductsForm"
      :model="form"
      :rules="rules"
      label-position="left"
      class="i-components"
      @submit.native.prevent
      @keyup.enter.native="onClick"
    >
      <el-row type="flex" align="middle">
        <el-col :sm="8" :xs="24" class="fs-20 mb-25 pr-15 black mb-m-5">
          Наименование культуры:
        </el-col>
        <el-col :sm="16" :xs="24">
          <el-form-item prop="full_name">
            <el-input v-model="form.full_name" autocomplete="nope" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" justify="end" class="mt-50 f-wrap">
        <div class="w-m-100 mt-m-20">
          <i-button
            :loading="loading"
            :disabled="loading"
            :text="id ? 'Обновить' : 'Создать'"
            width="250"
            @click="onClick"
          />
        </div>
      </el-row>
    </el-form>
  </i-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import IDialog from '@/components/Ui/Dialog'
import IButton from '@/components/Ui/Button'
import { settingsProductsFormRules } from './constants'

export default {
  name: 'EditProductsModal',
  components: { IDialog, IButton },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        if (val?.id) {
          this.id = val.id
          this.form = this.updateObject(this.form, val)
          this.form.full_name = val.full_name
        } else {
          this.id = 0
          if (this.$refs.editProductsForm)
            this.$refs.editProductsForm.resetFields()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getDialog: 'popups/getDialog',
      getLoading: 'products/getLoading'
    }),
    loading() {
      return this.getLoading()
    },
    item() {
      return this.getDialog(this.name).data || {}
    }
  },
  data() {
    return {
      name: 'edit-products-modal',
      rules: settingsProductsFormRules,
      id: 0,
      form: {
        full_name: ''
      }
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    ...mapActions('products', ['CREATE', 'UPDATE']),
    onClick() {
      this.$refs.editProductsForm.validate(valid => {
        if (valid) {
          const { id, form: data } = this
          id
            ? this.UPDATE({ id, data }).then(res => {
                if (res !== 'error') this.onClose('обновлена')
              })
            : this.CREATE(data).then(res => {
                if (res !== 'error') {
                  this.onClose('создана')
                  this.$emit('after', res.id)
                }
              })
        }
      })
    },
    onClose(text) {
      this.$message.success(`Культура успешно ${text}!`)
      this.setDialog({ name: this.name })
    }
  }
}
</script>
