<template>
  <page-wrapper
    :title="id ? 'РЕДАКТИРОВАНИЕ ТЕНДЕРА' : 'СОЗДАНИЕ ТЕНДЕРА'"
    link="Вернуться к списку"
    @click="$router.push({ name: 'Tenders' })"
  >
    <el-form
      ref="addTenderForm"
      :model="form"
      :rules="rules"
      label-position="top"
      class="add-tender i-components tender-form"
      @submit.native.prevent
    >
      <div class="add-tender__form-wrap">
        <load-unload-fields
          :config="config"
          :form="{
            load_region: form.load_region,
            load_city: form.load_city,
            load_address: form.load_address
          }"
          @setAddress="setAddress"
          @setRegion="setRegion"
          @setKladr="setKladr"
        />

        <form-wrapper
          title=""
          :rows="rows.consignee"
          showLinks
          @link="createConsignee"
        >
          <el-select slot="slot11" v-model="form.consignee_id">
            <el-option
              v-for="item in consignees"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            />
          </el-select>
          <el-input-number
            slot="slot12"
            v-model="form.distance"
            :controls="false"
            :min="0"
          />
        </form-wrapper>

        <form-wrapper
          title="Продукция"
          text
          showLinks
          :rows="rows.production"
          @link="createProduct"
        >
          <el-select slot="slot11" v-model="form.product_id">
            <el-option
              v-for="item in products"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            />
          </el-select>
          <el-input-number
            slot="slot12"
            v-model="form.load_weight_total"
            :controls="false"
            :min="0"
          />
          <el-input-number
            slot="slot13"
            v-model="form.load_weight_daily"
            :controls="false"
            :min="0"
          />
          <el-select slot="slot21" v-model="form.load_type_id">
            <el-option
              v-for="item in loadTypes"
              :label="item.name"
              :value="item.id"
              :key="item.id"
            />
          </el-select>
        </form-wrapper>

        <form-wrapper title="Условия тендера" :rows="rows.conditions">
          <el-date-picker
            slot="slot11"
            v-model="form.utime_load_start"
            type="date"
            placeholder="Выберите дату"
            :picker-options="pickerOptions"
            format="dd.MM.yyyy"
            value-format="timestamp"
          />
          <el-date-picker
            slot="slot12"
            v-model="form.utime_load_stop"
            type="date"
            placeholder="Выберите дату"
            :picker-options="pickerOptions"
            format="dd.MM.yyyy"
            value-format="timestamp"
          />
          <el-input-number
            slot="slot21"
            v-model="form.price_start"
            :controls="false"
            :min="0"
          />
        </form-wrapper>

        <form-wrapper title="Дополнительное описание" :rows="rows.descr">
          <el-input
            slot="slot11"
            type="textarea"
            :autosize="{ minRows: 5 }"
            v-model="form.comment"
            class="add-tender__textarea"
          />
        </form-wrapper>

        <form-wrapper
          title="Участники и сроки проведения тендера"
          :rows="rows.participant"
          :showLinks="!!form.utime_start"
          @link="setTime(60)"
          @link2="setTime(30)"
        >
          <el-date-picker
            slot="slot11"
            v-model="form.utime_start"
            type="datetime"
            placeholder="Выберите дату и время"
            :picker-options="pickerOptions"
            format="dd.MM.yyyy HH:mm"
            value-format="timestamp"
            @change="checkTime($event, 'utime_start')"
          />
          <el-date-picker
            slot="slot12"
            v-model="form.utime_stop"
            type="datetime"
            placeholder="Выберите дату и время"
            :picker-options="pickerOptions"
            format="dd.MM.yyyy HH:mm"
            value-format="timestamp"
            @change="checkTime($event, 'utime_stop')"
          />
        </form-wrapper>

        <form-wrapper title="Ответственный менеджер" :rows="rows.otvetstvenniy">
          <el-input
            placeholder=""
            v-model="form.otvetstvenniy_phone"
            v-mask="'+7 (###) ###-##-##'"
            autocomplete="nope"
            slot="slot11"
            clearable
          />
          <el-input
            placeholder=""
            v-model="form.otvetstvenniy_name"
            autocomplete="nope"
            slot="slot12"
            clearable
          />
        </form-wrapper>
      </div>

      <div class="add-tender__form-wrap add-tender__form-wrap--big">
        <participant-block
          :bidders="add_bidders"
          :drop-bidders="drop_bidders"
          :groups="add_bidders_groups"
          :drop-groups="drop_bidders_groups"
          :title="modalTitle"
          @update="updateField"
        />
        <el-row>
          <el-col v-if="formFieldsError" class="add-tender__error mb-20">
            ! Поля формы заполнены неверно, проверьте данные отмеченные красным
            цветом !
          </el-col>
          <el-col class="ta-r">
            <i-button
              :text="id ? 'Обновить тендер' : 'Разместить тендер'"
              :loading="loading"
              :disabled="loading"
              width="250"
              @click="onSaveTender"
            />
          </el-col>
        </el-row>
      </div>
    </el-form>
    <edit-consignee-modal @after="chooseConsignee" />
    <edit-products-modal @after="chooseProduct" />
  </page-wrapper>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { pickerOptions, autocompleteConfigLoad } from '@/data/constants'
import ApiFactory from '@/api/ApiFactory'
import IButton from '@/components/Ui/Button'
import PageWrapper from '@/components/Wrappers/PageWrapper'
import FormWrapper from '@/components/Wrappers/FormWrapper'
import LoadUnloadFields from '@/components/Reusable/LoadUnloadFields'
import EditConsigneeModal from '@/components/Modals/EditConsigneeModal'
import EditProductsModal from '@/components/Modals/EditProductsModal'
import ParticipantBlock from './ParticipantBlock'
import { rules, addTenderConfig } from '../constants'

const UsersApi = ApiFactory.get('approvedUsers')

export default {
  name: 'TenderForm',
  components: {
    ParticipantBlock,
    EditConsigneeModal,
    EditProductsModal,
    FormWrapper,
    LoadUnloadFields,
    PageWrapper,
    IButton
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.name === 'TenderEdit') {
          if (this.isBidder) return this.goToTender()
          const { id } = route.params
          if (id) this.updateForm(id)
        }
      }
    }
  },
  created() {
    this.consigneesFETCH({ ['per-page']: 999999 })
    this.productsFETCH({ ['per-page']: 999999 })
  },
  data() {
    return {
      rows: addTenderConfig,
      config: autocompleteConfigLoad,
      pickerOptions,
      rules,
      form: {
        load_region: '',
        load_city: '',
        load_district: '',
        load_address: '',
        consignee_id: undefined,
        distance: undefined,
        product_id: undefined,
        load_weight_total: undefined,
        load_weight_daily: undefined,
        load_type_id: undefined,
        utime_load_start: undefined,
        utime_load_stop: undefined,
        price_start: undefined,
        comment: '',
        utime_start: undefined,
        utime_stop: undefined,
        bidders_ids: [],
        groups_stats: [],
        otvetstvenniy_phone: '',
        otvetstvenniy_name: ''
      },
      add_bidders: [],
      drop_bidders: [],
      add_bidders_groups: [],
      drop_bidders_groups: [],
      formFieldsError: false,
      id: 0
    }
  },
  computed: {
    ...mapGetters({
      getLib: 'libs/getLib',
      getById: 'tenders/getById',
      getLoading: 'tenders/getLoading',
      consignees: 'consignees/getList',
      products: 'products/getList'
    }),
    loading() {
      return this.getLoading()
    },
    loadTypes() {
      return this.getLib('load-types')
    },
    modalTitle() {
      const { load_region, load_city, load_address } = this.form
      let title = load_region
      if (load_city && title) title += ', ' + load_city
      if (load_address && title) title += ', ' + load_address
      return title
    }
  },
  methods: {
    ...mapActions({
      consigneesFETCH: 'consignees/FETCH',
      productsFETCH: 'products/FETCH',
      CREATE: 'tenders/CREATE',
      UPDATE: 'tenders/UPDATE'
    }),
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    goToTender() {
      this.errHandler('Ошибка доступа')
      this.$router.push({ name: 'Tenders' })
    },
    createConsignee() {
      this.setDialog({
        name: 'edit-consignee-modal',
        visible: true
      })
    },
    chooseConsignee(id) {
      this.form.consignee_id = id
    },
    chooseProduct(id) {
      this.form.product_id = id
    },
    createProduct() {
      this.setDialog({
        name: 'edit-products-modal',
        visible: true
      })
    },
    updateForm(id) {
      const tender = this.getById(id)
      if (tender) {
        if (tender.bidders_ids) this.getBidders(id)
        this.id = id
        this.form = this.updateObject(this.form, tender)
        this.add_bidders_groups = this.form.groups_stats
      } else this.goToTender()
    },
    getBidders(for_tender) {
      UsersApi.getList({ for_tender, 'per-page': 999999 })
        .then(({ data }) => (this.add_bidders = data))
        .catch(err => this.errHandler(err))
    },
    onSaveTender() {
      this.formFieldsError = false
      this.$refs.addTenderForm.validate(valid => {
        if (valid) {
          const { id } = this
          const form = {
            ...this.form,
            utime_load_start: this.form.utime_load_start / 1000,
            utime_load_stop: this.form.utime_load_stop / 1000,
            utime_start: Math.ceil(this.form.utime_start / 1000),
            utime_stop: Math.ceil(this.form.utime_stop / 1000)
          }
          delete form.bidders_ids
          delete form.groups_stats
          const participants = this.generateBidders()
          const data = { ...form, ...participants }

          id
            ? this.UPDATE({ id, data }).then(res => {
                if (res !== 'error') {
                  this.$message.success('Тендер успешно обновлен!')
                  this.$router.push({ name: 'Tenders' })
                }
              })
            : this.CREATE(data).then(res => {
                if (res !== 'error') {
                  this.$message.success('Тендер успешно создан!')
                  this.$router.push({ name: 'Tenders' })
                }
              })
        } else {
          this.formFieldsError = true
        }
      })
    },
    generateBidders() {
      const participants = {}
      const {
        add_bidders,
        add_bidders_groups,
        drop_bidders,
        drop_bidders_groups
      } = this
      const { bidders_ids, groups_stats } = this.form
      const add_bidders_ids = add_bidders
        .filter(b => !bidders_ids.find(u => u === b.id))
        .flatMap(u => u.id)
      if (add_bidders_ids.length) participants.add_bidders_ids = add_bidders_ids
      const drop_bidders_ids = drop_bidders
        .filter(b => bidders_ids.find(u => u === b.id))
        .flatMap(u => u.id)
      if (drop_bidders_ids.length)
        participants.drop_bidders_ids = drop_bidders_ids
      const add_bidders_groups_ids = add_bidders_groups
        .filter(b => !groups_stats.find(g => g.id === b.id))
        .flatMap(u => u.id)
      if (add_bidders_groups_ids.length)
        participants.add_bidders_groups_ids = add_bidders_groups_ids
      const drop_bidders_groups_ids = drop_bidders_groups
        .filter(b => groups_stats.find(g => g.id === b.id))
        .flatMap(u => u.id)
      if (drop_bidders_groups_ids.length)
        participants.drop_bidders_groups_ids = drop_bidders_groups_ids

      return participants
    },
    updateField({ drop, add }, key = '') {
      this[`add_bidders${key}`] = add
      this[`drop_bidders${key}`] = drop
    },
    setTime(minutes) {
      this.form.utime_stop = this.form.utime_start + minutes * 60 * 1000
    },
    checkTime(val, key) {
      const now = Date.now()
      if (val < now) this.form[key] = now
    },
    setAddress(val) {
      this.form.load_address = val
    },
    setRegion(item, name) {
      this.config.kladr.params.regionId = item.id
      this.form[`${name}_region`] = item.name
      this.form[`${name}_city`] = ''
      this.form[`${name}_district`] = ''
    },
    setKladr(item, name) {
      const result = this.loadLocationFilter(item.name)
      this.form[`${name}_city`] = result.city
      this.form[`${name}_district`] = result.district
    }
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.add-tender
  @include to(768px)
    padding: 0 21px
  &__form-wrap
    width: 100%
    max-width: 934px
    &--big
      max-width: 1102px
  &__section-title
    font-weight: bold
    font-size: 20px
    line-height: 23px
    color: $textBlackDarkColor
    margin-bottom: 26px
  &__text
    font-weight: 300
    font-size: 14px
    line-height: 16px
    color: #8E8E8E
    margin-bottom: 30px
    @include to(768px)
      margin-bottom: 26px
  &__error
    font-size: 20px
    line-height: 23px
    text-align: right
    color: $colorError
    @include to(768px)
      text-align: left

  .el-form-item
    margin-bottom: 25px
  .el-input-number
    width: 100%
  &__section &__label .el-form-item__label,
  &__label
    font-weight: 500
    font-size: 14px
    line-height: 16px
    color: $textBlackDarkColor
    padding-bottom: 7px
  &__textarea
    font-size: 15px
  &__collapse-title
    margin-left: auto
    font-size: 20px
    line-height: 23px
    text-decoration-line: underline
    color: $blackColor
  .el-input
    @include to(768px)
      margin-bottom: 20px
  .responsive-column
    @include to(420px)
      width: 100%
  .is-error
    & .el-input__inner,
    & .el-input__inner::placeholder
      color: $colorError
  .el-form-item.is-error .el-input__inner,
  .el-date-editor.is-error .el-input__inner
    border-color: $colorError
  .el-form-item
    margin-bottom: 25px
    @include to(768px)
      margin-bottom: 0
  .el-form-item__label
    &::before
      display: none
</style>
